
















































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Widget from "@/components/common/Widget.vue";
import DataTable from "@/components/table/DataTable.vue";
import store from "../../store";
import api from "../../api";
import { Watch } from "vue-property-decorator";
import SelectFilter from "@/components/common/SelectFilter.vue";
import {
  setAgencyStorage,
  getAgencyStorage,
  getClientStorage,
} from "@/helper/utils";
import { getAuthTypeText } from "@/auth";

declare const $: any;

@Component({ components: { Breadcrumb, Widget, DataTable, SelectFilter } })
export default class ClientEmployees extends Vue {
  tableColums = [
    {
      label: "ID",
      prop: "id",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "E-Mail Adresse",
      prop: "username",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Vorname",
      prop: "firstname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Nachname",
      prop: "lastname",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Level",
      prop: "level",
      bodyCellClass: "text-left",
      sortable: true,
    },
    {
      label: "Auth verfahren",
      prop: "authTypeText",
      bodyCellClass: "text-left",
      sortable: true,
    },
  ];
  perPageList = [25, 50, 100, 250];
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any = [];
  filteredRows: any[] = [];
  user = {
    firstname: "",
    lastname: "",
    username: "",
    level: 3,
    password: "",
    passwordGenerated: true,
    userflags: 0,
  };
  userId = "";
  client: any = "";
  // agency = ''
  agencies: any[] = [];
  clients: any[] = [];
  loadedAgencies: any[] = [];
  userToDelete: any = "";
  selectedEmployee: any = "";
  listEmployees: any = [];
  userIdToUnassign: any = "";
  dropdownSearch: string = "";
  dropdownClientSearch: string = "";
  timer: any = null;
  cachedAgencies: any[] = [];
  cachedClients: any[] = [];
  isFormValidate: any = "";
  isErrorUsername: boolean = false;
  sortedClients: any = [];

  constructor() {
    super();
    // this.getAgencies()
    // this.getEmployees()
    if (getClientStorage()) {
      store.dispatch("updateSelectedAgency", getClientStorage()[0]);
      setAgencyStorage(getClientStorage()[0]);
      this.getAgencies();
      this.getEmployees();
    } else if (getAgencyStorage()) {
      store.dispatch("updateSelectedAgency", getAgencyStorage());
      this.getAgencies();
      this.getEmployees();
    } else {
      this.getAgencies();
      this.getEmployees();
    }
  }

  preventDefault(e: any) {
    e.stopPropagation();
  }

  @Watch("user", { immediate: true, deep: true })
  onUserChange() {
    if (
      this.user.firstname == "" ||
      this.user.lastname == "" ||
      this.user.username == "" ||
      (this.user.password == "" && !this.user.passwordGenerated)
    ) {
      this.isFormValidate = true;
    } else {
      this.isFormValidate = false;
    }
  }

  @Watch("cachedClients", { immediate: true, deep: true })
  onCachedClientsChange() {
    if (this.cachedClients.length > 0) {
      let tmpClients = [...this.cachedClients];
      tmpClients.sort((a, b) =>
        parseInt(a.mnumber) > parseInt(b.mnumber) ? 1 : -1
      );
      this.sortedClients = tmpClients;
      this.client = tmpClients[0] || "";
    }
  }

  clearOnEsc() {
    this.dropdownSearch = "";
    this.dropdownClientSearch = "";
    this.cachedAgencies = this.agencies;
    this.cachedClients = this.clients;
    //A way to handle Jquery component inside Vuejs
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }

  topBarSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedAgencies = this.agencies.filter((itm: any) => {
        if (
          this.dropdownSearch == "" ||
          itm[0].toLowerCase().includes(this.dropdownSearch.toLowerCase()) ||
          itm[1].toLowerCase().includes(this.dropdownSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }
  topBarClientSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedClients = this.clients.filter((itm: any) => {
        if (
          this.dropdownClientSearch == "" ||
          itm.name
            .toLowerCase()
            .includes(this.dropdownClientSearch.toLowerCase()) ||
          itm.mnumber
            .toLowerCase()
            .includes(this.dropdownClientSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }

  get agency() {
    return store.getters.selectedAgency;
  }

  getAgencieName(agencyId: string) {
    const agency = this.agencies.find((a) => a[0] === agencyId);
    return agency[1];
  }

  getAgencies() {
    api.getConfigClientMandators().then((res) => {
      //Chagne object ot array
      // const arrayOfObj = Object.entries(res.data).map((e) => ( { [e[0]]: e[1] } ));
      this.loadedAgencies = res.data;

      // if(this.agency != '' && this.loadedAgencies.length){
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.cachedClients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      // }
    });
    api.getAllStbs().then((res) => {
      const data: [string, string][] = res.data;
      const sortedAgencies = data.sort((a, b) => a[0].localeCompare(b[0]));
      this.agencies = sortedAgencies;
      this.cachedAgencies = sortedAgencies;

      store.dispatch("updateSelectedAgency", sortedAgencies[0][0]);
    });
  }

  async getEmployees() {
    if (this.agency) {
      await api
        .getEmployees(this.agency)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          let listEmp: any = [];
          res.data.forEach((d: any) => {
            if (d[4] == 3) {
              listEmp.push({
                id: d[0],
                username: d[1],
                firstname: d[2],
                lastname: d[3],
                level: d[4],
              });
            }
          });
          this.listEmployees = JSON.parse(JSON.stringify(listEmp));
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  async getEmployeeMandators() {
    if (this.agency && this.client) {
      this.pushStateHistory();
      let currentClient = this.client.mnumber;
      await api
        .getMandatorEmployees(this.agency, currentClient)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          this.tableRows = [];
          res.data.forEach((d: any) => {
            if (d[4] == 3) {
              this.tableRows.push({
                id: d[0],
                username: d[1],
                firstname: d[2],
                lastname: d[3],
                level: d[4],
                userflags: d[5] || 0,
                authTypeText: getAuthTypeText(d[5]),
              });
            }
          });
          this.filteredRows = JSON.parse(JSON.stringify(this.tableRows));
          this.totalItems = this.filteredRows.length;
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
  pushStateHistory() {
    // const selectedStb = (this.agency != null) ? this.agency : stb
    let path =
      "/einstellungen/mandant/mitarbeiter" +
      "/" +
      encodeURIComponent(this.agency) +
      "/" +
      encodeURIComponent(this.client.mnumber);
    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  @Watch("agency")
  onAgencyChange() {
    this.getEmployees();
    if (Object.keys(this.loadedAgencies).length > 0) {
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.cachedClients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      this.getEmployees();
    }
  }
  @Watch("loadedAgencies")
  onLoadedAgenciesChange() {
    if (this.agency && this.loadedAgencies.length > 0) {
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.cachedClients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      this.getEmployees();
    }
  }

  @Watch("client")
  onClientChange() {
    this.getEmployeeMandators();
  }

  changefilter(val: any, key: string) {
    this.offsetPage = 0;
    this.getEmployeeMandators();
  }

  setMandator(client: any) {
    localStorage.setItem("client", JSON.stringify(client));
    this.client = client;
    // store.dispatch('updateSelectedClient', client)
  }

  setAgency(agency: any) {
    localStorage.setItem("agency", JSON.stringify(agency));
    setAgencyStorage(agency);
    store.dispatch("updateSelectedAgency", agency);
  }

  addModal() {
    this.user = {
      firstname: "",
      lastname: "",
      username: "",
      level: 3,
      password: "",
      passwordGenerated: false,
      userflags: 0,
    };
    this.userId = "";
    this.isErrorUsername = false;
    $("#add_modal").modal();
  }

  passwordGenerate() {
    this.user.password = "";
  }

  async currentAssignedModal() {
    await this.getEmployees();
    // Use map to get a simple array of "val" values. Ex: [1,4]
    let yFilter = this.filteredRows.map((itemY: any) => {
      return itemY.id;
    });

    // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
    let filteredX = this.listEmployees.filter(
      (itemX: any) => !yFilter.includes(itemX.id)
    );

    // Print the result.
    this.listEmployees = [...filteredX];

    $("#assigned_modal").modal();
  }

  edit(event: any) {
    this.isErrorUsername = false;
    this.user = event;
    this.userId = event.id;
    $("#add_modal").modal();
  }

  changePermission(event: any) {
    this.user = event;
    this.userId = event.id;
    $("#permission_modal").modal();
  }

  onDeleteModal(event: any) {
    $("#delete_modal").modal();
    this.userToDelete = event.id;
  }

  unassignEmployee(event: any) {
    this.userIdToUnassign = event.id;
    $("#unassign_modal").modal();
  }

  unassignClientToAgency() {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .unlinkEmployeeMandator(
        this.agency,
        this.client.mnumber,
        this.userIdToUnassign
      )
      .then((res) => {
        // let getAgencies = Object.keys(res.data)
        // this.agencies   = getAgencies
        store.dispatch("updateLoading", store.state.loading - 1);

        this.getEmployeeMandators();
        this.getEmployees();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getEmployeeMandators();
      });
  }

  deleteItem(event: any) {
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .deleteEmployee(this.agency, this.userToDelete)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.getEmployeeMandators();
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  add() {
    this.isErrorUsername = false;
    if (this.userId) {
      this.tableRows.forEach((row: any) => {
        if (row.id === this.userId) {
          row = { ...this.user, id: row.id };
        }
      });

      // waiting for update API
      api
        .updateUser(this.userId, { ...this.user, enabled: true })
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          if (this.user.passwordGenerated) {
            api.forgotPassword(this.user.username).then((res) => {});
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          this.getEmployeeMandators();
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .postEmployee(this.agency, this.user)
        .then((res) => {
          store.dispatch("updateLoading", store.state.loading - 1);
          if (this.user.passwordGenerated) {
            api.setGeneratedPasswordUsers(res.data.id).then((res) => {
              store.dispatch("updateLoading", store.state.loading - 1);
            });
          } else {
            store.dispatch("updateLoading", store.state.loading - 1);
          }
          //linkEmployeeMandator
          api
            .linkEmployeeMandator(this.agency, this.client.mnumber, res.data.id)
            .then((res) => {
              this.getEmployeeMandators();
            })
            .catch((err) => {
              this.getEmployeeMandators();
            });
          $("#add_modal").modal("hide");
        })
        .catch((err) => {
          this.isErrorUsername = true;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
  linkExistingEmployee() {
    //linkEmployeeMandator
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    api
      .linkEmployeeMandator(
        this.agency,
        this.client.mnumber,
        this.selectedEmployee
      )
      .then((res) => {
        this.getEmployeeMandators();
        store.dispatch("updateLoading", store.state.loading - 1);
        this.selectedEmployee = "";
      })
      .catch((err) => {
        this.getEmployeeMandators();
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  search(event: any) {
    if (event) {
      this.filteredRows = this.tableRows.filter((row: any) => {
        return (
          row.firstname.toLowerCase().includes(event.toLowerCase()) ||
          row.id
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.level
            .toString()
            .toLowerCase()
            .includes(event.toString().toLowerCase()) ||
          row.lastname.toLowerCase().includes(event.toLowerCase()) ||
          row.username.toLowerCase().includes(event.toLowerCase())
        );
      });
    } else {
      this.filteredRows = this.tableRows;
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = "asc";
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }

  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.clearOnEsc();
      }
    });
  }
}
